@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-image: url(../src/assets/bg-blue3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

body {
  margin: 0;
  font-family: "Quicksand",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
}

/* ------------------------------------------------------ */
/* -- Navigation -- */

/* header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 25px;
} */

.header,
.about,
.projects,
.experience,
.contact,
.footer {
  padding: 50px 25px 25px;
}

/* ------------------------------------------------------ */
/* -- Header -- */

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 500;
  text-align: center;
}

.header img {
  max-width: 300px;
  height: auto;

  margin: 20px auto;

  /*border-radius: 100%;*/
  border-radius: 4rem;
}

.header__content {
  width: 400px;
  margin: 0 auto;
}

.header__content h1 {
  font-size: 48px;
}

.header__content p {
  font-size: 32px;
  font-weight: 600;
  color: rgb(107 114 128);
  padding-bottom: 2rem;
}

.header__content button {
  margin: 25px auto 0;
}

.header__socials {
  padding-top: 2rem;
}

.header__socials-icon {
  font-size: 2.5rem;
  color: rgb(107 114 128);
  padding: 0.7rem;
}

.header__socials-icon:hover {
  color: #0088BB;
}



/* ------------------------------------------------------ */
/* -- About -- */

.about h2 {
  font-size: 42px;
  margin: 25px auto;
}

.about h3 {
  font-size: 25px;
  padding-bottom: 2rem;
}

.about p {
  font-size: 18px;
  line-height: 1.5rem;
  max-width: 75ch;
  font-weight: 600;
  padding-bottom: 2rem;
}

.about ul {
  padding-left: 2rem;
  line-height: 1.5rem;
}

/* ------------------------------------------------------ */
/* -- Projects -- */

.projects h2 {
  font-size: 42px;
  margin: 25px auto;
}

.projects p {
  font-size: 18px;
  line-height: 130%;
  max-width: 50ch;
}

.projects__cards {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.projects__card {
  max-width: 350px;

  padding: 20px;

  border: 1px solid #0088BB;
  border-radius: 8px;
}

.projects__card h3 {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 10px;
}

.projects__card p {
  font-size: 16px;
  margin: 20px 0;
}

.projects__card img {
  width: 100%;
  height: auto;
  margin: 20px 0 10px;

  border-radius: 8px;
}

.projects__card .button {
  margin: 10px 5px 0;
}

/* -- Popup Box -- */
.popup__box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transition: all 250ms ease;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  padding: 20px;
  border: 1px solid #999;
  overflow-y: auto;
  border-radius: 2rem;
}

.aws__narrative {
  background: #fff;
}

.structure__container {
  background: #fff;
}

.structure__image {
  background: #fff;
}

.aws__text {
  min-width: 90%;
  background: #fff;
}


.popup__box h4 {
  background: #fff;
  font-size: 1.5rem;
  padding-bottom: 1.5rem;
}

.popup__box h5 {
  background: #fff;
  font-size: 1rem;
  font-weight: 600;
}

.popup__box b {
  background: #fff;
  font-weight: 700;
}

.popup__box p {
  background: #fff;
}


.popup__box img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  width: 50%;
  height: 50%;
  padding-bottom: 4rem;
  background: #fff;
  position: relative;
}

.close__icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.popup__box {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* ------------------------------------------------------ */
/* -- Experience/Education -- */

.experience h2 {
  font-size: 42px;
  margin: 25px auto;
}

.experience h3 {
  font-size: 25px;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.experience ul {
  margin: 0 0 10px 50px;
}

.experience li {
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  margin: 15px auto;
}

.education__container {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.education h4 {
  font-size: 20px;
}

.education h5 {
  font-weight: 600;
  font-size: 16px;
}

.edu__dates {
  color: rgb(107 114 128);
}

/* ------------------------------------------------------ */
/* -- Contact -- */

.contact h2 {
  font-size: 42px;
  margin: 25px auto;
}

.contact .button {
  margin: 0 15px;
}

.contact__options {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.contact__option {
  display: inline-block;

  background: #0088BB;
  color: black;
  padding: 15px 50px;
  border: none;
  border-radius: 9999px;
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: all 250ms ease;
  text-align: center;
}

.contact__option:hover {
  background: #12bdf3;
  /* make background a little transparent */
}

.contact__option h4 {
  background: transparent;
  text-decoration: none;
  padding-top: 4px;
  color: black;
  font-size: 18px;
}

.contact__option h5 {
  background: transparent;
  text-decoration: none;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: black;
  font-size: 14px;
}

.contact__option a {
  background: transparent;
  text-decoration: none;
  padding-top: 4px;
  color: black;
  font-size: 14px;
}

.contact__option-icon {
  background: transparent;
  font-size: 1.5rem;
}

/* ------------------------------------------------------ */
/* -- Footer -- */
.footer {
  margin: 0 auto;
  text-align: center;
}

/* ------------------------------------------------------ */
/* -- HTML COMPONENTS: BUTTON -- */
.button {
  display: inline-block;

  background: #0088BB;
  color: #FFFFFF;

  padding: 15px 50px;

  border: none;
  border-radius: 9999px;
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;

  cursor: pointer;

  transition: all 250ms ease;
}

.button:hover {
  background: #12BDF3;
  color: black;
}

.popup__button {
  display: inline-block;

  background: #0088BB;
  color: #FFFFFF;

  padding: 15px 50px;

  border: none;
  border-radius: 9999px;
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;

  cursor: pointer;

  transition: all 250ms ease;
}

.popup__button:hover {
  background: #12BDF3;
  color: black;
}

/* ========== MEDIA QUERIES ================== */
/* ----- Contact ------ */

/* Small Devices */
@media screen and (max-width: 600px) {
  .contact__options {
    flex-direction: column;
    justify-content: center;
    width: 70%;
    padding-left: 25%;
  }

  .education__container {
    flex-direction: column;
    padding-left: 2rem;
    gap: 3rem;
  }

}